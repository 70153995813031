@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imggame {
  object-fit: contain;
  width: 100%;
}
.centered-image {
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s, filter 0.3s, width 0.3s, height 0.3s;
}

@media screen and (max-width: 600px) {
  .clicked {
    width: 100%;
    height: auto;
    z-index: 999;
  }
}

@media screen and (min-width: 600px) {
  .clicked {
    width: 80%;
    max-width: 800px;
    height: auto;
    z-index: 999;
  }
}
