@media screen and (max-width: 640px) {
  .susimg {
    max-width: 100px;

    height: auto;
  }
}

@media screen and (min-width: 640px) {
  .susimg {
    max-width: 125px;
  }
}

.bkgchk {
  border: 5px solid red;
}
